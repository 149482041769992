<template>
  <div class="administrativeToken">
    <div class="code" v-if="isShow">{{ code }}</div>
    <div class="not-data" v-else>
      <img src="./img//not-found.png" alt="" />
      <span>暂无权限访问</span>
    </div>
  </div>
</template>

<script>
import { getBackendToken, showBackendToken } from "./api.js";
export default {
  name: "administrativeToken",
  components: {},
  props: {},
  data() {
    return {
      interval: null,
      code: "",
      isShow: false,
    };
  },
  created() {},
  computed: {
    mobile() {
      return this.$store.state.mobile;
    },
  },
  mounted() {
    this.showBackendToken();
  },
  methods: {
    //管理后台令牌
    async showBackendToken() {
      const params = {
        mobileNum: this.mobile,
      };
      const res = await this.$axios.post(showBackendToken, params);

      if (res.code == 200) {
        this.isShow = res.data;
        this.getBackendToken();
        this.interval = setInterval(this.getBackendToken, 60000);
      }
    },
    getBackendToken() {
      const params = {
        mobileNum: this.mobile || "",
      };
      this.$axios.post(getBackendToken, params).then((res) => {
        if (res.code == 200) {
          this.code = res.msg;
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped lang="less">
.administrativeToken {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;

  .code {
    width: 100%;
    height: 168px;
    text-align: center;
    line-height: 168px;
    color: blue;
    letter-spacing: 10px;
    font-weight: 700;
    font-size: 48px;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
  }
  .not-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 300px;
    img {
      width: 580px;
      height: 340px;
      margin: 0 auto;
    }
    span {
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      color: rgba(76, 76, 76, 0.5);
      line-height: 42px;
    }
  }
}
</style>
